<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="block" style="margin-bottom:50px">
        <h1 class="text-2xl font-bold">Terminal Delivery Portal</h1>
        <h3 class="text-sm">
          Select the states that Traction can deliver POS Terminals to
        </h3>
        <div
          style="height:300px;overflow:auto;border: solid 2px #ccc;border-radius:5px"
          class="mt-5 p-2"
        >
          <div :key="i" v-for="(s, i) in filterTerminals">
            <input
              v-model="new_states"
              :value="s"
              type="checkbox"
              :id="s.name"
              class="mr-2"
            />
            <label :for="s.name">{{ s.name }}</label>
          </div>
        </div>
        <button
          @click="submitStates"
          class="py-3 px-6 mt-3 bg-blue-800 float-right rounded-md font-semibold text-white text-sm"
        >
          Add New State
        </button>
      </div>
      <div>
        <h3 class="text-sm">
          This is a lists of states added, use the select to remove states
          <div class="bg-blue-100 p-5">
            Please note that you can only remove one state at a time.
          </div>
        </h3>
        <div
          style="height:250px;overflow:auto;border: solid 2px #ccc;border-radius:5px"
          class="mt-5 p-2"
        >
          <div :key="i" v-for="(s, i) in available_states">
            <input
              v-model="deleted_state"
              :value="s"
              type="checkbox"
              :id="s.name"
              class="mr-2"
            />
            <label :for="s.name">{{ s.name }}</label>
          </div>
        </div>
        <button
          v-if="deleted_state.length > 0"
          @click="updateStates"
          class="py-3 px-6 mt-3 bg-blue-800 float-right rounded-md font-semibold text-white text-sm"
        >
          Remove selected state
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      states: [],
      available_states: [],
      payload: [],
      new_states: [],
      deleted_state: [],
    };
  },
  computed: {
    ...mapGetters(["GET_STATES"]),
    filterTerminals() {
      // return this.GET_STATES.data.filter((state) =>
      //   this.available_states.includes(state)
      // );
      const avail_states = this.available_states || [];
      const states = this.states;

      const difference = [
        ...this.getDifference(avail_states, states),
        ...this.getDifference(states, avail_states),
      ];

      difference.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return difference;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    let res = await this.$store.dispatch("FETCH_STATES");
    let res_available = await this.$store.dispatch("FETCH_AVAILABLE_STATES");
    if (res.status === true) {
      this.available_states = res_available.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.isLoading = false;
      this.states = res.data;
      // this.$router.go();
    }
  },
  methods: {
    getDifference(array1 = [], array2 = []) {
      return array1.filter((object1) => {
        return !array2.some((object2) => {
          return object1.name === object2.name;
        });
      });
    },
    async submitStates() {
      const response = confirm(
        "Are you sure you want to perform this operation?"
      );
      if (response) {
        this.isLoading = true;
        this.payload = this.available_states;

        try {
          let res = await this.$store.dispatch(
            "ADD_TERMINAL_LOCATION",
            this.new_states
          );
          if (res.status == true) {
            this.isLoading = false;
            alert("Update was successful");
            this.$router.go();
          }
        } catch (error) {}

        this.isLoading = false;
      } else {
        console.log(this.new_states);
      }
    },
    async updateStates() {
      const response = confirm(
        "Are you sure you want to perform this operation?"
      );
      if (response) {
        // console.log(this.deleted_state);
        this.isLoading = true;
        this.payload = this.deleted_state[0];

        try {
          let res = await this.$store.dispatch(
            "REMOVE_TERMINAL_LOCATION",
            this.deleted_state[0]
          );
          if (res.status == true) {
            this.isLoading = false;
            alert("Update was successful");
            this.$router.go();
          }
        } catch (error) {}
        this.isLoading = false;
      } else {
        // console.log(this.new_states);
      }
    },
    check: function(e) {
      if (this.excludeAbsent === true) {
        for (var i = 0; i < this.absentStudents.length; i++) {
          if (e.target.value == this.absentStudents[i].id) {
            this.absentStudents[i].include = true;
          } else {
            this.absentStudents[i].include = false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: #000;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
